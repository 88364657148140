import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import HomePage2 from "../HomePage2/HomePage2";
import HomePage3 from "../HomePage3/HomePage3";
import HomePage4 from "../HomePage4/HomePage4";
import ServicePage from "../ServicePage/ServicePage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import PricingPage from "../PricingPage/PricingPage";
import ProjectPage from "../ProjectPage/ProjectPage";
import ProjectSinglePage from "../ProjectSinglePage/ProjectSinglePage";
import AboutPage from "../AboutPage/AboutPage";
import ShopPage from "../ShopPage";
import ProductSinglePage from "../ProductSinglePage";
import CartPage from "../CartPage";
import CheckoutPage from "../CheckoutPage";
// import TeamPage from "../TeamPage/TeamPage";
// import TeamSinglePage from "../TeamSinglePage/index";
import OrderRecived from "../OrderRecived";
// import BlogPage from "../BlogPage/BlogPage";
// import BlogPageLeft from "../BlogPageLeft/BlogPageLeft";
// import BlogPageFullwidth from "../BlogPageFullwidth/BlogPageFullwidth";
// import BlogDetails from "../BlogDetails/BlogDetails";
// import BlogDetailsFull from "../BlogDetailsFull/BlogDetailsFull";
// import BlogDetailsLeftSiide from "../BlogDetailsLeftSiide/BlogDetailsLeftSiide";
import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="home-2" element={<HomePage2 />} />
          <Route path="home-3" element={<HomePage3 />} />
          <Route path="home-4" element={<HomePage4 />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="project-single/:slug" element={<ProjectSinglePage />} />
          <Route path="category/:slug" element={<ProjectSinglePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="product-single/:slug" element={<ProductSinglePage />} />
          <Route path="product/:slug/:id" element={<ProductSinglePage />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="pricing" element={<PricingPage />} />
          {/* <Route path="team" element={<TeamPage />} /> */}
          {/* <Route path="team-single/:slug" element={<TeamSinglePage />} /> */}
          {/* <Route path="blog" element={<BlogPage />} />
          <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
          <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />
          <Route
            path="blog-single-fullwidth/:slug"
            element={<BlogDetailsFull />}
          />
          <Route
            path="blog-single-left-sidebar/:slug"
            element={<BlogDetailsLeftSiide />}
          /> */}
          <Route path="contact" element={<ContactPage />} />
          <Route path="order_received" element={<OrderRecived />} />
          <Route path="404" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
